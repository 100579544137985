/**
 * Theme
 * 
 */
import jQuery from "jquery";
import Popper from 'popper.js';
import {
    collapse,
    button,
    dropdown,
    modal,
    alert
} from 'bootstrap';

import LazyLoad from "vanilla-lazyload";
import * as smoothScroll from './smoothscroll';
import "objectFitPolyfill";



// Make jQuery globally available
// Should work in webpack as well.
window.$ = window.jQuery = jQuery;


let video_play_button = document.querySelector(".icon-play");
if (video_play_button) {
    video_play_button.addEventListener('click', () => {
        let video = video_play_button.parentElement.children[0];
        console.log(video);
        video.play();
        video.controls = true;
        video_play_button.style.display = "none";
    });
}

document.addEventListener('readystatechange', event => {
    if (event.target.readyState === 'interactive') {
    }
    else if (event.target.readyState === 'complete') {
      initScrolls();
    }
  });

  function initScrolls(){
    let scroll_main = document.querySelector(".icon--scroll-main");
    let scroll_hero = document.querySelector(".icon--scroll-hero");
    if (scroll_main) {
        console.log(scroll_main);

        scroll_main.addEventListener('click', () => {
            let parent_header = scroll_main.closest('header');
            parent_header.nextElementSibling.scrollIntoView({
                //  block: 'start',
                // inline: 'nearest',
                behavior: 'smooth'
            });
        });
    }
    
    
    if (scroll_hero) {
        scroll_hero.addEventListener('click', () => {
            let parent_header = scroll_hero.closest('header');
            console.log(parent_header);
            parent_header.nextElementSibling.scrollIntoView({
                //  block: 'start',
                // inline: 'nearest',
                behavior: 'smooth'
            });
        });
    }

  }


  // Intersection observer controls display/switch of navigation bar
var headerObserverCallback = function (entries, observer) {
    entries.forEach(entry => {
      let mainNavigation = document.querySelector('#main-menu');
      // Each entry describes an intersection change for one observed
      // target element:
      //   entry.boundingClientRect
      //   entry.intersectionRatio
      //   entry.intersectionRect
      //   entry.isIntersecting
      //   entry.rootBounds
      //   entry.target
      //   entry.time
      console.log(entry.intersectionRatio);
      console.log(entry.isIntersecting)
      if (!entry.isIntersecting) {
        mainNavigation.classList.add("clr-black");
      } else {
        mainNavigation.classList.remove("clr-black");
      }
    });
  };
  var headerObserverOptions = {
   // rootMargin: '-50px',
    threshold: 0
  }
  var headerObserver = new IntersectionObserver(headerObserverCallback, headerObserverOptions);
  
  var target = document.querySelector('main .header');
  headerObserver.observe(target);
  