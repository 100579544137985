/**
 * Import
 * 
 */
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

/**
 * Scroll Top
 */

function f() {
    var button_scrollTop = dataJs('scroll-top');
    button_scrollTop.forEach((button, index) => {
        button_scrollTop[index].addEventListener('click', function (event) {
            event.preventDefault();
            console.log(event);
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        });
    })
}


(function () {
    /**
     * ScrollTo Startseite
     */

    // Scroll into view  ( Nodelist);
    let scroll_target = document.querySelector('#home-division-claims-1');

    // Scroll Trigger(s)
    const target = document.getElementById('home-scrollTo');
    
    if (target != null) {

        target.addEventListener('click', function (e) {

            e.preventDefault();
            scroll_target.scrollIntoView({
              //  block: 'start',
                // inline: 'nearest',
                behavior: 'smooth'
            });
        });
    }
})();

(function () {
    /**
     * ScrollTo Referenzen
     */

    // Scroll into view  ( Nodelist);
    let scroll_target = document.querySelector('.referenzen');
    // Only debug
    //console.log(scroll_target);

    // Scroll Trigger(s)
    const target = document.getElementById('referenzen-scrollTo')
    // Only debug
    //console.log(target);
    if (target != null) {
        target.addEventListener('click', function (e) {
            e.preventDefault();
            scroll_target.scrollIntoView({
                block: 'start',
                // inline: 'nearest',
                behavior: 'smooth'
            });
        });
    }

})();


(function () {
    /**
     * ScrollTo Referenz
     */

    // Scroll into view  ( Nodelist);
    let scroll_target = document.querySelectorAll('#referenz-division-title + [class*="container-fluid"]');
    // Only debug
    //console.log(scroll_target);

    // Scroll Trigger(s)
    const target = document.getElementById('project-scrollTo');
    // Only debug
    if (target != null) {

        target.addEventListener('click', function (e) {
            e.preventDefault();
            scroll_target[0].scrollIntoView({
                block: 'start',
                // inline: 'nearest',
                behavior: 'smooth'
            });
        });
    }
})();



let anchors = document.querySelectorAll('.dropdown-menu li a');
Array.prototype.map.call(anchors, (anchor) => {
    anchor.addEventListener("click", (event) => {
        //   event.preventDefault();
      //  console.log(event)
    //    console.log(anchor.href)

    })
});



// this function handles scrolling to data-anchors
(function () {

    window.onhashchange = function (event) {
        console.log('hash changed')

        // return hash if so or null if hash is empty
        function getHash() {
            var hash = window.location.hash.replace('#', '');
            if (hash != '') {
                console.log(hash);
                return hash;
            } else {
                return null;
            }
        }

        // first get your element by attribute selector
        var elem = document.querySelector('[id="' + getHash() + '"]');
        // check if element exists
        console.log(elem.getBoundingClientRect())
        if (elem) {
            console.log(elem)
         /*   elem.scrollIntoView({
                block: 'start',
                 inline: 'nearest',
                behavior: 'smooth'
            });*/
            window.scrollTo({
                // set position but take navbar height into calculation ( 60px )
                top: ( elem.getBoundingClientRect().top - 60 ) + window.pageYOffset,
                left: 0,
                behavior: 'smooth'
            });
        }
       
    }

})()



let icon_links = document.querySelectorAll('.vc_icon_element-link');
console.log(icon_links);

Array.prototype.map.call(icon_links, (link) => {
    link.addEventListener("click", (event) => {
       event.preventDefault();
       let link_target = link.hash;
       let target = document.getElementById(link_target.replace('#',''));
       //let target = document.querySelector("id="'+link_target.replace('#','')+'");
       console.log(target);
       target.scrollIntoView({
        behavior: 'smooth'
    });
     

    })
});
